<template>
  <b-modal v-model="modalShow" size="md" centered hide-footer  @close="hide">
    <template #modal-header>Product Serial Detail</template>
    <InputTextArea v-model="serial_detail"  textFloat="Serial Detail" disabled  rows="6" placeholder=""> </InputTextArea>
  </b-modal>
</template>
<script>
import InputTextArea from "@/components/inputs/InputTextArea";

export default {
  components: {
    InputTextArea,
  },
  data() {
    return {
      serial_detail: "",
      modalShow: false,
    };
  },
  methods: {
     show(detail){
      this.modalShow = true;
      this.serial_detail = detail;
    },
    hide(){
      this.serial_detail = ""
      this.modalShow = false;
    }
  },
};
</script>
